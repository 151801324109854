import { FunctionComponent } from "react";

type Props = {
  headerText: string;
  description: string | JSX.Element;
};

const StyledToastBodyPuspenerbal: FunctionComponent<Props> = ({
  description,
  headerText,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h5 style={{ fontWeight: 600, marginBottom: 2 }}>{headerText}</h5>
      <p style={{ fontWeight: 400, marginBottom: 0, paddingRight: 24 }}>
        {description}
      </p>
    </div>
  );
};

export default StyledToastBodyPuspenerbal;
