import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../../api/config";
import CookieMonster from "../../../api/CookieMonster";
import { APersonel } from "../../../api/personelAPI";
import {
  convertToEncodedURL,
  jsonToFormData,
} from "../../../assets/usefulFunctions";
import { APaginationEntity, BackendDataPageShape } from "../../../types";
import { BackendPaginationRequestObject } from "../../../types/endpoints/personel";
import {
  AEligibleRank,
  AEligibleRankRequestObject,
} from "../../../types/endpoints/personel/dalpers";
import {
  AGetPersonelReqObj,
  APersonelCreateObj as APersonelNewObj,
  APersonelObj,
} from "../../../types/endpoints/personel/personel";

export const personelStoreAPI = createApi({
  reducerPath: "personelStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["PersonelList", "ApprovalList", "PersonelEligibleList"],
  endpoints: (builder) => {
    return {
      getPersonel: builder.query<APersonel[], APersonelObj>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/personel/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<APersonel[]>) =>
          response.data,
        providesTags: (_, __, { id }) => [
          "PersonelList",
          { type: "PersonelList", id },
        ],
      }),
      getPersonelPagination: builder.query<
        APaginationEntity<APersonel[]>,
        BackendPaginationRequestObject<Partial<AGetPersonelReqObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/personel/find/pagination" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<APersonel[]>) =>
          response.data,
        providesTags: (_, __, { id }) => [
          "PersonelList",
          { type: "PersonelList", id },
        ],
      }),
      getPersonelEligiblePangkatList: builder.query<
        APaginationEntity<AEligibleRank[]>,
        BackendPaginationRequestObject<AEligibleRankRequestObject>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/personel/eligible_pangkat" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<APaginationEntity<AEligibleRank[]>>,
        ) => response.data,
        providesTags: (_, __, arg) => [
          "PersonelEligibleList",
          { type: "PersonelEligibleList", id: arg.periode },
        ],
      }),
      createPersonel: builder.mutation<
        BackendDataShape<object>,
        APersonelNewObj
      >({
        query: (obj) => {
          const data = jsonToFormData(obj);
          return {
            url: "/personel/create",
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["PersonelList"],
      }),
      addPangkatSkPersonel: builder.mutation<
        BackendDataShape<object>,
        {
          suratKeputusan: string;
          idPersonel: number;
          pangkat: string;
          file: File[];
          tmtPangkat: string;
        }
      >({
        query: ({ idPersonel, ...rest }) => {
          const data = jsonToFormData(rest);
          return {
            url: `/personel/${idPersonel}/pangkat/sk`,
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["PersonelList", "PersonelEligibleList"],
      }),
      updatePersonel: builder.mutation<BackendDataShape<object>, APersonelObj>({
        query: ({ id, ...rest }) => {
          const data = jsonToFormData(rest);
          return {
            url: "/personel/update/" + id,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["PersonelList"],
      }),
      updatePersonelAddress: builder.mutation<
        BackendDataShape<object>,
        Partial<Pick<APersonel, "address" | "id">>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: "/personel/update/address/" + id,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["PersonelList"],
      }),
    };
  },
});

export const {
  useGetPersonelQuery,
  useGetPersonelPaginationQuery,
  useGetPersonelEligiblePangkatListQuery,
  useCreatePersonelMutation,
  useAddPangkatSkPersonelMutation,
  useUpdatePersonelMutation,
  useUpdatePersonelAddressMutation,
  util: { resetApiState: resetPersonelStoreAPI },
} = personelStoreAPI;
