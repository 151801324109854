import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AccountObj, AnAccount } from "../../api/account";
import { BackendDataShape, UsedAPI } from "../../api/config";
import { LoginObj } from "../../api/credentialsAPI";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";

export const userStoreAPI = createApi({
  reducerPath: "userStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/user`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["UserList", "User"],
  endpoints: (builder) => {
    return {
      letUserLogin: builder.mutation({
        query: (obj: LoginObj) => {
          return {
            url: "/login",
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["UserList"],
      }),
      getUser: builder.query<AnAccount[], Partial<AccountObj>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AnAccount[]>) =>
          response.data,
        providesTags: ["UserList"],
      }),

      createUser: builder.mutation<
        BackendDataShape<AnAccount>,
        Partial<AccountObj>
      >({
        query: (obj) => {
          return {
            url: "/register",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["UserList"],
      }),

      updateUser: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<AccountObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["UserList"],
      }),

      deleteUser: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["UserList"],
      }),
      uploadProfileUserImage: builder.mutation<
        BackendDataShape<string>,
        FormData
      >({
        query: (obj) => {
          return {
            url: `/picture/upload`,
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["User"],
      }),
    };
  },
});

export const {
  useGetUserQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useUploadProfileUserImageMutation,
  util: { resetApiState: resetUserStoreAPI },
} = userStoreAPI;
