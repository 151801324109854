import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import CookieMonster from "api/CookieMonster";
import { BackendDataShape } from "api/config";

import { convertToEncodedURL } from "assets/usefulFunctions";
import {
  ADashboardAgePesud,
  ADashboardDisposedPesud,
  ADashboardReadiness,
  ADashboardTotalPesud,
} from "types/endpoints/dashboard/dashboardSisfolog";

// temporary, will be changed
const UsedAPI = "https://sisfologal.datacakra.com/api";

export const dashboardSisfologStoreAPI = createApi({
  reducerPath: "dashboardSisfologStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/dashboard`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["DashboardSisfologList"],
  endpoints: (builder) => {
    return {
      getDashboardTotalPesud: builder.query<
        ADashboardTotalPesud[],
        Partial<ADashboardTotalPesud>
      >({
        query: ({ ...rest }) => {
          let params = "";
          if (rest) {
            params = convertToEncodedURL(rest);
            params = params.length ? "?" + params : "";
          }
          let url = "/total-pesud" + params;
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["DashboardSisfologList"],
        transformResponse: (
          response: BackendDataShape<ADashboardTotalPesud[]>,
        ) => response.data,
      }),

      getDashboardDisposedPesud: builder.query<
        ADashboardDisposedPesud[],
        Partial<ADashboardDisposedPesud>
      >({
        query: ({ ...rest }) => {
          let params = "";
          if (rest) {
            params = convertToEncodedURL(rest);
            params = params.length ? "?" + params : "";
          }
          let url = "/disposed-pesud" + params;
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["DashboardSisfologList"],
        transformResponse: (
          response: BackendDataShape<ADashboardDisposedPesud[]>,
        ) => response.data,
      }),

      getDashboardAgePesud: builder.query<
        number[],
        Partial<ADashboardAgePesud>
      >({
        query: ({ ...rest }) => {
          let params = "";
          if (rest) {
            params = convertToEncodedURL(rest);
            params = params.length ? "?" + params : "";
          }
          let url = "/age-pesud" + params;
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["DashboardSisfologList"],
        transformResponse: (response: BackendDataShape<number[]>) =>
          response.data,
      }),

      getDashboardReadinessWar: builder.query<
        ADashboardReadiness[],
        Partial<ADashboardReadiness>
      >({
        query: ({ ...rest }) => {
          let params = "";
          if (rest) {
            params = convertToEncodedURL(rest);
            params = params.length ? "?" + params : "";
          }
          let url = "/readiness-war" + params;
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["DashboardSisfologList"],
        transformResponse: (
          response: BackendDataShape<ADashboardReadiness[]>,
        ) => response.data,
      }),

      getDashboardPesudType: builder.query<string[], string>({
        query: () => {
          let url = "/pesud-type";
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["DashboardSisfologList"],
        transformResponse: (response: BackendDataShape<string[]>) =>
          response.data,
      }),

      getDashboardPesudSatker: builder.query<string[], string>({
        query: () => {
          let url = "/pesud-satker";
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["DashboardSisfologList"],
        transformResponse: (response: BackendDataShape<string[]>) =>
          response.data,
      }),
    };
  },
});

export const {
  useGetDashboardTotalPesudQuery,
  useGetDashboardDisposedPesudQuery,
  useGetDashboardReadinessWarQuery,
  useGetDashboardAgePesudQuery,
  useGetDashboardPesudTypeQuery,
  useGetDashboardPesudSatkerQuery,
  util: { resetApiState: resetDashboardSisfologStoreAPI },
} = dashboardSisfologStoreAPI;
