import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useGetSisfoParentUrl = () => {
  const location = useLocation();
  const url = location.pathname;
  return useMemo(() => {
    if (!url) return "";
    const sisfoParentUrl = url.split("/");
    return sisfoParentUrl[1];
  }, [url]);
};

export default useGetSisfoParentUrl;
