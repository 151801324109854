import React from "react";
import { useAppSelector } from "..";

const useUserIsPersonel = (): boolean => {
  const userFromStore = useAppSelector((state) => state.userStore.userData);
  return React.useMemo<boolean>(() => {
    if (!userFromStore) return false;
    return userFromStore.usertype.role === "user";
  }, [userFromStore]);
};

export default useUserIsPersonel;
