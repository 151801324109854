import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../../api/config";
import CookieMonster from "../../../api/CookieMonster";
import { convertToEncodedURL } from "../../../assets/usefulFunctions";
import {
  ALawCase,
  CaseStory,
  ALawObj,
} from "../../../types/endpoints/personel/law";

export const caseStoreAPI = createApi({
  reducerPath: "caseStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["CaseList"],
  endpoints: (builder) => {
    return {
      getKasus: builder.query<ALawCase[], ALawObj>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/case" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ALawCase[]>) =>
          response.data,
        providesTags: ["CaseList"],
      }),

      createKasus: builder.mutation<
        BackendDataShape<Pick<ALawCase, "id">[]>,
        Partial<ALawCase>
      >({
        query: (obj) => {
          return {
            url: "/case",
            body: obj,
            method: "POST",
          };
        },
        transformResponse: (response: BackendDataShape<ALawCase[]>) => response,
        invalidatesTags: ["CaseList"],
      }),

      updateKasus: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<ALawCase>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/case/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["CaseList"],
      }),

      updateStoryKasus: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<CaseStory>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/case/${id}/story`,
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["CaseList"],
      }),

      updateChangeStoryKasus: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          storyId: number;
          data: Partial<CaseStory>;
        }
      >({
        query: ({ data, id, storyId }) => {
          return {
            url: `/case/${id}/story/${storyId}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["CaseList"],
      }),

      updateFinalStoryKasus: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<ALawCase>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/case/${id}/update_final`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["CaseList"],
      }),
    };
  },
});

export const {
  useGetKasusQuery,
  useCreateKasusMutation,
  useUpdateKasusMutation,
  useUpdateStoryKasusMutation,
  useUpdateChangeStoryKasusMutation,
  useUpdateFinalStoryKasusMutation,
  util: { resetApiState: resetCaseStoreAPI },
} = caseStoreAPI;
