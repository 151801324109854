import { CircularProgress } from "@mui/material";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { COLORS } from "../../../../assets/theme";

type Props = {};

const StyledLoaderContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
`;

const LoaderPage: FunctionComponent<Props> = () => {
  return (
    <StyledLoaderContainer>
      <CircularProgress
        variant="indeterminate"
        color="inherit"
        style={{ width: 32, height: 32, color: COLORS.blue_2 }}
      />
    </StyledLoaderContainer>
  );
};

export default LoaderPage;
