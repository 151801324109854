import React, { Suspense } from "react";
import { createUseStyles } from "react-jss";
import { puspenerbalBackgroundMapImage } from "../assets/images";
import { BasicTheme } from "../assets/theme";
import LoaderPage from "../components/atoms/Loader/LoaderPage/LoaderPage";
import PageContainer from "../components/PageContainer";

type Props = {
  navbarItems: {
    title: string;
    url: string;
  }[];
};

const useStyles = createUseStyles((_: BasicTheme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "row",
      minHeight: "100vh",
      backgroundColor: "#000912",
      backgroundImage: `url(${puspenerbalBackgroundMapImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    sidebar: {
      flexBasis: "230px",
      maxHeight: "100vh",
      display: "flex",
    },
    outlet: {
      flex: 1,
      // backgroundColor: theme.backgroundColor,
      maxHeight: "100vh",
      overflowY: "auto",
    },
  };
});

const Layout: React.FunctionComponent<Props> = ({ children, navbarItems }) => {
  const classes = useStyles();
  // const sisfoParentUrl = useGetSisfoParentUrl();
  return (
    <div className={classes.container}>
      <div className={classes.outlet}>
        {/**
         * @todo add new loading fallback here
         */}
        <Suspense fallback={<LoaderPage />}>
          <PageContainer withControl navbarItems={navbarItems}>
            {children}
          </PageContainer>
        </Suspense>
      </div>
    </div>
  );
};

export default Layout;
