import {
  Action,
  configureStore,
  isRejectedWithValue,
  Middleware,
  ThunkAction,
} from "@reduxjs/toolkit";
import { UnknownAsyncThunkRejectedWithValueAction } from "@reduxjs/toolkit/dist/matchers";
import { toast } from "react-toastify";

import userStore from "store/userStore";
import sidebarStore from "store/sidebarStore";
import { userStoreAPI } from "store/userStore/userStoreAPI";
import { personelStoreAPI } from "store/sisfopers/personelStore/personelStoreAPI";
import { usertypeStoreAPI } from "store/usertypeStore/usertypeStoreAPI";
// import { personelApprovalStoreAPI } from "./sisfopers/personelStore/personelApprovalAPI";
import { lovStoreAPI } from "store/sisfopers/lovStore/lovStoreAPI";
import { additionaltypeStoreAPI } from "store/sisfopers/additionaltypeStore/additionaltypeStoreAPI";
import { caseStoreAPI } from "store/sisfopers/caseStore/caseStoreAPI";
import { masterDataStoreAPI } from "store/sisfopers/masterDataStore/masterDataStoreAPI";
import { recommendPositionStoreAPI } from "store/sisfopers/recommendPositionStore/recommendPositionStoreAPI";
import { dashboardStoreAPI } from "store/sisfopers/dashboardStore/dashboardStoreAPI";
import { educationEqualityStoreAPI } from "store/sisfopers/masterDataStore/education/educationEqualityStore";
import { dashboardSisfologStoreAPI } from "store/sisfolog/dashboardStore";
import { dashboardSisfoK3StoreAPI } from "store/sisfoK3/dashboardStore/dashboardStoreAPI";

import { ErrorMessageBackendDataShape } from "types";

export const store = configureStore({
  /**
   * @todo sort these reducer slices alphabetically, ascending.
   */
  reducer: {
    userStore,
    sidebarStore,
    [additionaltypeStoreAPI.reducerPath]: additionaltypeStoreAPI.reducer,
    [caseStoreAPI.reducerPath]: caseStoreAPI.reducer,
    [lovStoreAPI.reducerPath]: lovStoreAPI.reducer,
    [masterDataStoreAPI.reducerPath]: masterDataStoreAPI.reducer,
    [personelStoreAPI.reducerPath]: personelStoreAPI.reducer,
    // [personelApprovalStoreAPI.reducerPath]: personelApprovalStoreAPI.reducer, // injected to personalStoreAPI
    [recommendPositionStoreAPI.reducerPath]: recommendPositionStoreAPI.reducer,
    [userStoreAPI.reducerPath]: userStoreAPI.reducer,
    [usertypeStoreAPI.reducerPath]: usertypeStoreAPI.reducer,
    [dashboardStoreAPI.reducerPath]: dashboardStoreAPI.reducer,
    [educationEqualityStoreAPI.reducerPath]: educationEqualityStoreAPI.reducer,
    [dashboardSisfologStoreAPI.reducerPath]: dashboardSisfologStoreAPI.reducer,
    [dashboardSisfoK3StoreAPI.reducerPath]: dashboardSisfoK3StoreAPI.reducer,
  },
  middleware: (getMiddleWares) => {
    /**
     * @todo remove this logger if no longer needed
     */
    const logMiddleWare: Middleware = () => {
      return (next) => {
        return (action) => {
          if (isRejectedWithValue(action)) {
            const act = action as UnknownAsyncThunkRejectedWithValueAction;
            const payload = act.payload;

            const isBEErrorPayload = (
              payload: any,
            ): payload is ErrorMessageBackendDataShape => {
              return payload?.data?.status && payload.data.status === "error";
            };
            if (isBEErrorPayload(payload)) {
              toast.error(payload.data.message);
              // console.log(payload.data.message)
            }
          }
          return next(action);
        };
      };
    };

    /**
     * @todo sort these middlewares alphabetically, ascending.
     */
    let middlewares = getMiddleWares().concat([
      additionaltypeStoreAPI.middleware,
      caseStoreAPI.middleware,
      lovStoreAPI.middleware,
      logMiddleWare,
      masterDataStoreAPI.middleware,
      personelStoreAPI.middleware,
      // personelApprovalStoreAPI.middleware, // injected to personalStoreAPI
      recommendPositionStoreAPI.middleware,
      userStoreAPI.middleware,
      usertypeStoreAPI.middleware,
      dashboardStoreAPI.middleware,
      educationEqualityStoreAPI.middleware,
      dashboardSisfologStoreAPI.middleware,
      dashboardSisfoK3StoreAPI.middleware,
    ]);
    return middlewares;
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
