import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import { COLORS } from "../../assets/theme";

export const StyledToastContainerPuspenerbal = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity

  &&&.Toastify__toast-container {
  }
  :root {
    --toastify-icon-color-success: ${COLORS.white};
  }
  .Toastify__toast {
    border-radius: 0;
    z-index: 10000;
  }
  .Toastify__toast-body {
    /* align-items: flex-start; */
  }
  .Toastify__toast--success {
    background-color: ${COLORS.blue_1_puspenerbal};
    color: ${COLORS.white};
  }
  .Toastify__toast--error {
    background-color: ${COLORS.red_1_puspenerbal};
    color: ${COLORS.white};
  }
  .Toastify__toast-body > div:last-child {
    font-family: "Roboto Condensed", "Open Sans", sans-serif;
  }
  .Toastify__toast-body > div > svg {
    fill: white;
    color: white;
    opacity: 1;
  }
  .Toastify__progress-bar--success {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .Toastify__progress-bar--error {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .Toastify__progress-bar {
  }
  .Toastify__close-button {
    fill: white;
    color: white;
    opacity: 1;
  }
  .Toastify__close-button > svg {
    fill: white;
    color: white;
    opacity: 1;
  }
  .Toastify__close-button:hover {
    fill: white;
    color: white;
    opacity: 1;
  }
`;
