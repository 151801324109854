import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BackendDataShape, UsedAPI } from "../../../api/config";
import CookieMonster from "../../../api/CookieMonster";
import { AUserAdditionalType } from "../../../types/endpoints/personel";

export const additionaltypeStoreAPI = createApi({
  reducerPath: "additionaltypeStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/useradditionaltype`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["AdditionalTypeList"],
  endpoints: (builder) => ({
    getAdditionalTypes: builder.query<AUserAdditionalType[], {}>({
      query: () => {
        return {
          url: "/find",
          method: "GET",
        };
      },
      transformResponse: (response: BackendDataShape<AUserAdditionalType[]>) =>
        response.data,
      providesTags: ["AdditionalTypeList"],
    }),
  }),
});

export const {
  useGetAdditionalTypesQuery,
  util: { resetApiState: resetAdditionalTypeQuery },
} = additionaltypeStoreAPI;
