import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../../api/config";
import CookieMonster from "../../../api/CookieMonster";
import { convertToEncodedURL } from "../../../assets/usefulFunctions";
import { DashboardPuspenerbalLinks } from "../../../config/nav";
import {
  DashboardDSP,
  DashboardFlightActivity,
} from "../../../types/endpoints/dashboard/dashboard";

export const dashboardStoreAPI = createApi({
  reducerPath: "dashboardStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["DashboardDSPList", "DashboardFlightActivityList"],
  endpoints: (builder) => {
    return {
      getDashboardDSP: builder.query<DashboardDSP, {}>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = `${DashboardPuspenerbalLinks.dashboard_by_dsp}` + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<DashboardDSP>) =>
          response.data,
        providesTags: ["DashboardDSPList"],
      }),

      getDashboardFlightActivity: builder.query<DashboardFlightActivity, {}>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url =
            `${DashboardPuspenerbalLinks.dashboard_by_aktifitas_penerbangan}` +
            params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<DashboardFlightActivity>,
        ) => response.data,
        providesTags: ["DashboardFlightActivityList"],
      }),
    };
  },
});

export const {
  useGetDashboardDSPQuery,
  useGetDashboardFlightActivityQuery,
  util: { resetApiState: resetDashboardStoreAPI },
} = dashboardStoreAPI;
