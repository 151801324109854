import { UsedAPI } from "../api/config";

/**
 * @todo clear this ListOfRoutes from rapidsense routes and add new Puspenerbal paths
 */
export const ListOfRoutes = {
  homepage: "/",
  loginpage: "/login",

  dashboard: "/dashboard",

  management: "/management",

  reports: "/reports",
  /**
   *  @deprecated clear this and its respective pages
   */
  schedule: "/schedule",
  /**
   *  @deprecated clear this and its respective pages
   * @todo add links for another state such as
   * verify: "/verification/:verifyState"
   *
   * so then we can show page depending on the `verifyState`
   */
  verify: "/verification/success",

  /**
   *  @deprecated clear this and its respective pages
   * Dedicated machine OEE, used by operator
   */
  machineDetails: "/machine",

  profile: "/profile",
} as const;

export const ReportLinks = {
  filter_by_caseLog: (params: string) => `${UsedAPI}/case${params}`,
} as const;

export const DashboardPuspenerbalLinks = {
  get dashboard_by_dsp(): string {
    return `${UsedAPI}/dashboard/rekap_dsp`;
  },

  get dashboard_by_aktifitas_penerbangan(): string {
    return `${UsedAPI}/dashboard/aktivitas_penerbangan`;
  },
} as const;

export class BaseSisfoRoutes {
  constructor(public baseURL: string) {}

  get home(): string {
    return `${this.baseURL}`;
  }

  get dashboard(): string {
    return `${this.baseURL}/dashboard`;
  }

  get login(): string {
    return `/login/${this.baseURL}`;
  }

  get notification(): string {
    return `${this.baseURL}/notification`;
  }
  get reports(): string {
    return `${this.baseURL}/reports`;
  }

  /**
   * @todo profile should only be in sisfopers
   */
  get profile(): string {
    return `${this.baseURL}/profile`;
  }

  get logout(): string {
    return `${this.baseURL}/logout`;
  }
}

export class SisfopimpinanRoutes extends BaseSisfoRoutes {
  constructor() {
    super("/sisfo-pimpinan");
  }

  get dashboard_personel(): string {
    return `${this.dashboard}/personel`;
  }

  get dashboard_operasi(): string {
    return `${this.dashboard}/operasi`;
  }

  get dashboard_logistik(): string {
    return `${this.dashboard}/logistik`;
  }

  get dashboard_lambangja(): string {
    return `${this.dashboard}/lambangja`;
  }
  
  get dashboard_tu(): string {
    return `${this.dashboard}/tu`;
  }
}

export class SisfologRoutes extends BaseSisfoRoutes {
  constructor() {
    super("/sisfo-log");
  }
}

export class SisfoOpsRoutes extends BaseSisfoRoutes {
  constructor() {
    super("/sisfo-ops");
  }
}
