import React, { ReactNode, RefObject, useRef, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import styled from "styled-components";
import useNotificationStyles from "./PageContainer.style";
import { COLORS } from "../assets/theme";
import { BreadCrumbObj } from "../types/breadCrumb";
import {
  puspenerbalBackgroundMapImage_2,
  puspenerballogo,
} from "../assets/images";
import TimeDisplays from "./TimeDisplays";
import useGetSisfoParentUrl from "../hooks/useGetSisfoParentUrl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { BaseSisfoRoutes } from "../config/nav";
import useUserIsPersonel from "../hooks/identity/useUserIsPersonel";
import usePersonelId from "../hooks/identity/usePersonelId";
import { useGetPersonelQuery } from "../store/sisfopers/personelStore/personelStoreAPI";
import { concatPerwiraLabelInNrp } from "../assets/usefulFunctions";

interface Props {
  withControl?: boolean;
  title?: string | ReactNode;
  isFixedWidth?: boolean;
  titleRightChildren?: React.ReactNode;
  breadCrumbItems?: BreadCrumbObj[];
  navbarItems?: {
    title: string;
    url: string;
  }[];
}

export const PageWrapper = styled.div`
  display: flex;
  height: 100vh;
`;

export const TitleRightChildrenWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* align-items: center; */
  margin-bottom: 8px;
`;

const StyledMainWrapper = styled.main`
  margin-top: 16px;
`;

export const StyledSisfoPuspenerbalLoginBackgroundWrapper = styled.div`
  background-color: #000912;
  background-image: url(${puspenerbalBackgroundMapImage_2});
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const StyledSisfoPuspenerbalWrapper = styled.div`
  background: rgba(123, 123, 123, 0.35);
  border: 1px solid #f2f2f2;
`;
export const StyledSisfoPuspenerbalBlurredWrapper = styled.div`
  background: ${COLORS.gradient_backgroundCard};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid #7b7b7b;
  color: ${COLORS.white};
`;

const PageContainer: React.FunctionComponent<Props> = ({
  children,
  withControl,
  title,
  isFixedWidth,
  titleRightChildren,
  breadCrumbItems,
  navbarItems,
}) => {
  const sisfoParentUrl = useGetSisfoParentUrl();
  const customRoutes = new BaseSisfoRoutes("/" + sisfoParentUrl);
  const classes = useNotificationStyles();
  const menuNotificationRef = useRef(null) as RefObject<HTMLDivElement>;
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const user: any = useAppSelector((state) => state.userStore.userData);
  const isPersonel = useUserIsPersonel();
  const personilId = usePersonelId();
  const { pathname: activeUrl } = useLocation();
  /**
   * @todo use isLoading to mitigate empty data
   */
  const { data: personelData } = useGetPersonelQuery(
    { id: personilId ? personilId : undefined },
    {
      /**
       * we will let personel to fetch data without supplying personilId
       */
      skip: !personilId && !isPersonel,
    },
  );

  const defaultUsername = user?.username;
  const username = React.useMemo(() => {
    if (isPersonel) {
      if (!personelData || !personelData[0]) return defaultUsername;
      const d = personelData[0];
      return concatPerwiraLabelInNrp(d.nrp, d.rankCategoryGeneral);
    }
    return defaultUsername;
  }, [isPersonel, defaultUsername, personelData]);

  return (
    <div
      className={classes.container}
      style={{ width: isFixedWidth ? "auto" : "100%" }}
    >
      <div className={classes.navContainer}>
        <div className={classes.brandContainer}>
          <img width={40} src={puspenerballogo} alt="puspenerbal" />
          <p>PUSAT PENERBANGAN TNI AL</p>
        </div>
        <ul className={classes.navLinksContainer}>
          {navbarItems?.map((item, index) => {
            const isActive = activeUrl === item.url;
            return (
              <li key={index}>
                <Link
                  className={isActive ? classes.activeUrl : classes.linkUrl}
                  to={item.url}
                >
                  {item.title}
                </Link>
              </li>
            );
          })}
        </ul>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TimeDisplays breadCrumbItems={breadCrumbItems} />
          {withControl ? (
            <div className={classes.controlContainer} ref={menuNotificationRef}>
              <div>
                <Dropdown
                  isOpen={showDropdown}
                  toggle={() => setShowDropdown((prev) => !prev)}
                >
                  <DropdownToggle
                    style={{
                      color: COLORS.white,
                      background: "none",
                      border: "none",
                      flex: 1,
                      justifyContent: "center",
                      display: "flex",
                      fontSize: 14,
                      alignItems: "center",
                    }}
                  >
                    <p style={{ marginBottom: 0, marginTop: 2 }}>
                      {username ?? ""}
                    </p>
                    <MdArrowDropDown
                      size={24}
                      fontSize={24}
                      fill={COLORS.white}
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      style={{ fontSize: 12 }}
                      onClick={() => navigate(customRoutes.logout)}
                    >
                      Keluar
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              {/* <button
              title="Logout"
              className={classes.logoutBtn}
              onClick={() => {
                logout();
              }}
            >
              <IoMdExit size={18} fontSize={18} />
            </button> */}
            </div>
          ) : (
            []
          )}
        </div>
      </div>
      <StyledMainWrapper>
        {titleRightChildren ? (
          <TitleRightChildrenWrapper>
            <h1 className={classes.titleHeading}>{title}</h1>
            {titleRightChildren}
          </TitleRightChildrenWrapper>
        ) : (
          <h1 className={classes.titleHeading}>{title}</h1>
        )}
        <div className={classes.childrenContainer}>{children}</div>
      </StyledMainWrapper>
    </div>
  );
};

export default PageContainer;
