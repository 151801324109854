import { useParams } from "react-router-dom";
import { useAppSelector } from "..";

const usePersonelId = () => {
  const params = useParams<"id">();
  const user = useAppSelector((state) => state.userStore.userData);
  if (!user) return undefined;
  switch (user.usertype.role) {
    case "user":
      return user.userdetail?.id;
    default:
      return params.id ? parseInt(params.id) : user.userdetail?.id;
  }
};

export default usePersonelId;
