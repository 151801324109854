import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape } from "api/config";
import CookieMonster from "api/CookieMonster";
import { convertToEncodedURL } from "assets/usefulFunctions";

import {
  IGetDashboardReqObj,
  IDashboardTotalPesudAccident,
  IDashboardMaxPesudAccident,
  IDashboardPesudCondition,
  IDashboardFactorAccident,
} from "types/endpoints/dashboard/sisfoK3";

// temporary, will be changed
const UsedAPI = "https://sisfok3al.datacakra.com/api";

export const dashboardSisfoK3StoreAPI = createApi({
  reducerPath: "dashboardSisfoK3StoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: [
    "DashboardSisfoK3TotalPesudAccidentList",
    "DashboardSisfoK3MaxPesudAccidentList",
    "DashboardSisfoK3PesudConditionList",
    "DashboardSisfoK3FactorAccidentList",
  ],
  endpoints: (builder) => {
    return {
      getDashboardSisfoK3TotalPesudAccident: builder.query<
        IDashboardTotalPesudAccident[],
        Partial<IGetDashboardReqObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/dashboard/total-pesud-accident" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<IDashboardTotalPesudAccident[]>,
        ) => {
          return response.data;
        },
        providesTags: (_, __, { id }) => [
          "DashboardSisfoK3TotalPesudAccidentList",
          { type: "DashboardSisfoK3TotalPesudAccidentList", id },
        ],
      }),
      getDashboardSisfoK3MaxPesudAccident: builder.query<
        IDashboardMaxPesudAccident[],
        Partial<IGetDashboardReqObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/dashboard/max-pesud-accident" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<IDashboardMaxPesudAccident[]>,
        ) => {
          return response.data;
        },
        providesTags: (_, __, { id }) => [
          "DashboardSisfoK3MaxPesudAccidentList",
          { type: "DashboardSisfoK3MaxPesudAccidentList", id },
        ],
      }),
      getDashboardSisfoK3PesudCondition: builder.query<
        IDashboardPesudCondition,
        Partial<IGetDashboardReqObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/dashboard/pesud-condition" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<IDashboardPesudCondition>,
        ) => {
          return response.data;
        },
        providesTags: (_, __, { id }) => [
          "DashboardSisfoK3PesudConditionList",
          { type: "DashboardSisfoK3PesudConditionList", id },
        ],
      }),
      getDashboardSisfoK3FactorAccident: builder.query<
        IDashboardFactorAccident,
        Partial<IGetDashboardReqObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/dashboard/factor-accident" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<IDashboardFactorAccident>,
        ) => {
          return response.data;
        },
        providesTags: (_, __, { id }) => [
          "DashboardSisfoK3FactorAccidentList",
          { type: "DashboardSisfoK3FactorAccidentList", id },
        ],
      }),
    };
  },
});

export const {
  useGetDashboardSisfoK3TotalPesudAccidentQuery,
  useGetDashboardSisfoK3MaxPesudAccidentQuery,
  useGetDashboardSisfoK3PesudConditionQuery,
  useGetDashboardSisfoK3FactorAccidentQuery,
  util: { resetApiState: resetDashboardSisfoK3StoreAPI },
} = dashboardSisfoK3StoreAPI;
