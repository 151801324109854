import React from "react";
import { HashRouter, Route, Routes, Outlet, Navigate } from "react-router-dom";

import LoaderPage from "components/atoms/Loader/LoaderPage/LoaderPage";
import AuthHOC from "components/auth/AuthHOC";
import UserGate from "components/auth/UserGate";
import { StyledToastContainerPuspenerbal } from "components/Toast/StyledToastContainerPuspenerbal";

import { ListOfRoutes, SisfopimpinanRoutes } from "config/nav";
import Layout from "layout/Layout";
import NoMatch from "pages/NoMatch";
import Redirector from "pages/Redirector";

const LoginPage = React.lazy(() => import("./pages/LoginPage"));

const SisfopersDashboardPage = React.lazy(
  () => import("./pages/SisfopersDashboardPage"),
);

const SisfologDashboardPage = React.lazy(
  () => import("./pages/SisfologDashboardPage"),
);

const SisfoopsDashboardPage = React.lazy(
  () => import("./pages/SisfoopsDashboardPage"),
);

const Sisfok3DashboardPage = React.lazy(
  () => import("./pages/Sisfok3DashboardPage"),
);

const SisfoTUDashboardPage = React.lazy(
  () => import("pages/SisfoTUDashboardPage"),
);

const SisfoPersLogoutPage = React.lazy(() => import("./pages/Logout"));

const sisfopimpinanRoutes = new SisfopimpinanRoutes();

// const allowedForApprovalPage: (KnownUsertype | KnownUsertypeName)[] = [
//   "kasubsiJasrek", // Data Umum Personel
//   "kasiWatpers", // Data Garjas
//   // "kasiMindik"

//   "kasubsiDatapers", // Master Data

//   "kasiMindik", // Data Pendidikan

//   "kasubsiDalpersmil", // Data Dalpers A
//   "kasiDalpersmil", // Data Dalpers A
//   "kasiDalperssip", // Data Dalpers A

//   "kasubsiUrikes", // Data Kes E
//   "kasiKesud", // Data Kes E
//   // "kasiMinkes", // @todo Approval apa?

//   "kasubsiJasrek", // Data Watpers B
//   "kasiWatpers", // Data Watpers B
//   // "kasiBintal", // @todo Approval apa?

//   // "kasiKum", // Data Kum F, perlu approval?

//   "kasubsiEvadal", // Data Profesi Penerbang D
//   "kasiProfesiPenerbang", // Data Profesi Penerbang D
// ];

const navbarItems = [
  {
    title: "OVERVIEW",
    url: "/not-found",
  },
  {
    title: "SISFO-PERS",
    url: sisfopimpinanRoutes.dashboard_personel,
  },
  {
    title: "SISFO-LOG",
    url: sisfopimpinanRoutes.dashboard_logistik,
  },
  {
    title: "SISFO-TU",
    url: sisfopimpinanRoutes.dashboard_tu,
  },
  {
    title: "SISFO-OPS",
    url: sisfopimpinanRoutes.dashboard_operasi,
  },
  {
    title: "SISFO-LAMBANGJA",
    url: sisfopimpinanRoutes.dashboard_lambangja,
  },
];

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={
              <AuthHOC>
                <Outlet />
              </AuthHOC>
            }
          >
            <Route
              index
              element={
                <Navigate to={`${ListOfRoutes.loginpage}/sisfo-pimpinan`} />
              }
            />

            <Route
              path={sisfopimpinanRoutes.baseURL}
              element={
                <Layout navbarItems={navbarItems}>
                  <AuthHOC>
                    <Outlet />
                  </AuthHOC>
                </Layout>
              }
            >
              <Route index element={<Redirector />} />
              <Route
                path={sisfopimpinanRoutes.dashboard_personel}
                element={
                  <UserGate
                    allowedUsers={[
                      "kasubsiDalpersmil",
                      "kasiDalpersmil",
                      "kasiDalperssip",
                      "kasubsiDatapers",
                      "kasiMindik",
                      "kasubditMinpers",
                    ]}
                  >
                    <SisfopersDashboardPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopimpinanRoutes.dashboard_logistik}
                element={
                  <UserGate
                    allowedUsers={[
                      "kasubsiDalpersmil",
                      "kasiDalpersmil",
                      "kasiDalperssip",
                      "kasubsiDatapers",
                      "kasiMindik",
                      "kasubditMinpers",
                    ]}
                  >
                    <SisfologDashboardPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopimpinanRoutes.dashboard_tu}
                element={
                  <UserGate
                    allowedUsers={[
                      "kasubsiDalpersmil",
                      "kasiDalpersmil",
                      "kasiDalperssip",
                      "kasubsiDatapers",
                      "kasiMindik",
                      "kasubditMinpers",
                    ]}
                  >
                    <SisfoTUDashboardPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopimpinanRoutes.dashboard_operasi}
                element={
                  <UserGate
                    allowedUsers={[
                      "kasubsiDalpersmil",
                      "kasiDalpersmil",
                      "kasiDalperssip",
                      "kasubsiDatapers",
                      "kasiMindik",
                      "kasubditMinpers",
                    ]}
                  >
                    <SisfoopsDashboardPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopimpinanRoutes.dashboard_lambangja}
                element={
                  <UserGate
                    allowedUsers={[
                      "kasubsiDalpersmil",
                      "kasiDalpersmil",
                      "kasiDalperssip",
                      "kasubsiDatapers",
                      "kasiMindik",
                      "kasubditMinpers",
                    ]}
                  >
                    <Sisfok3DashboardPage />
                  </UserGate>
                }
              />

              <Route
                path={sisfopimpinanRoutes.logout}
                element={<SisfoPersLogoutPage />}
              />
            </Route>
          </Route>
          <Route
            path={ListOfRoutes.loginpage}
            element={
              <React.Suspense fallback={<LoaderPage />}>
                <Outlet />
              </React.Suspense>
            }
          >
            <Route path={sisfopimpinanRoutes.login} element={<LoginPage />} />
            <Route
              index
              element={
                <Navigate to={`${ListOfRoutes.loginpage}/sisfo-pimpinan`} />
              }
            />
          </Route>

          <Route path="*" element={<NoMatch />} />
        </Routes>
      </HashRouter>
      <StyledToastContainerPuspenerbal />
    </>
  );
}

export default App;
