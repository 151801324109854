import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AMasterDataEducationEqualityParentList } from "../../../../types/endpoints/masterData/masterData";
import CookieMonster from "../../../../api/CookieMonster";
import { BackendDataShape, UsedAPI } from "../../../../api/config";
import { convertToEncodedURL } from "../../../../assets/usefulFunctions";

export const educationEqualityStoreAPI = createApi({
  reducerPath: "educationEqualityStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["EducationEqualityParentList"],
  endpoints: (builder) => {
    return {
      getMasterDataEducationEqualityParentList: builder.query<
        AMasterDataEducationEqualityParentList[],
        { jenisPendidikan: "DIKMA" | "DIKBANGSPES" | "DIKPROF" | "DIKUM" }
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/pendidikan/pendidikanKesetaraan" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<AMasterDataEducationEqualityParentList[]>,
        ) => response.data,
        providesTags: ["EducationEqualityParentList"],
      }),
    };
  },
});

export const {
  useGetMasterDataEducationEqualityParentListQuery,
  util: { resetApiState: resetEducationEqualityStoreAPI },
} = educationEqualityStoreAPI;
